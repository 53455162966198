import { useNavigate } from "react-router-dom";
import pb from "./../lib/pocketbase.js";

const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    pb.authStore.clear();
    navigate("/login", { replace: true });
  };

  return logout;
};

export default useLogout;
