import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import pb from "./lib/pocketbase.js";
import useLogout from "./hooks/useLogout";

const NavBar = lazy(() => import("./core/NavBar"));
const Page404 = lazy(() => import("./core/Page404"));
const Login = lazy(() => import("./core/Login"));
const Dashboard = lazy(() => import("./core/Dashboard"));
const Newtest = lazy(() => import("./core/Newtest"));
const Test = lazy(() => import("./core/Test"));
const Questions = lazy(() => import("./core/Questions"));
const Settings = lazy(() => import("./core/Settings"));

const routeConfig = [
  { path: "/", element: Login, private: false },
  { path: "/login", element: Login, private: false },
  { path: "/dashboard", element: Dashboard, private: true },
  { path: "/new-test", element: Newtest, private: true },
  { path: "/test", element: Test, private: true },
  { path: "/questions", element: Questions, private: true },
  { path: "/settings", element: Settings, private: true },
  { path: "*", element: Page404, private: false },
];

const PrivateRoute = ({ children }) => {
  return pb.authStore.isValid ? children : <Navigate to="/login" replace />;
};

const Layout = ({ children }) => {
  return (
    <div>
      <NavBar user={pb.authStore.model} useLogout={useLogout()} />
      {children}
    </div>
  );
};

const App = () => {
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <Routes>
          {routeConfig.map(
            ({ path, element: Component, private: isPrivate }) => (
              <Route
                key={path}
                path={path}
                element={
                  isPrivate ? (
                    <PrivateRoute>
                      {path == "/test" ? (
                        <Component />
                      ) : (
                        <Layout>
                          <Component />
                        </Layout>
                      )}
                    </PrivateRoute>
                  ) : (
                    <Component />
                  )
                }
              />
            )
          )}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
